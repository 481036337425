const colors = {
	great: '#2EB62C',
	good: '#83D475',
	average: '#C5E8B7',
};

const skillData = [
	{ key: 0, label: 'JavaScript', color: colors.great, link: 'fakelink.com' },
	{
		key: 1,
		label: 'React.js Framework',
		color: colors.great,
		link: 'fakelink.com',
	},
	{
		key: 2,
		label: 'Vue.js Framework',
		color: colors.good,
		link: 'fakelink.com',
	},
	{ key: 3, label: 'Node.js', color: colors.good, link: 'fakelink.com' },
	{ key: 4, label: 'Go', color: colors.average, link: 'fakelink.com' },
	{ key: 5, label: 'Python', color: colors.great, link: 'fakelink.com' },
	{ key: 6, label: 'Zappa', color: colors.good, link: 'fakelink.com' },
	{
		key: 7,
		label: 'Flask Framework',
		color: colors.great,
		link: 'fakelink.com',
	},
	{
		key: 8,
		label: 'Django Framework',
		color: colors.good,
		link: 'fakelink.com',
	},
	{
		key: 9,
		label: 'beautifulSoup Library',
		color: colors.good,
		link: 'fakelink.com',
	},
	{
		key: 10,
		label: 'Serverless Framework',
		color: colors.great,
		link: 'fakelink.com',
	},
	{ key: 11, label: 'AWS s3', color: colors.great, link: 'fakelink.com' },
	{
		key: 12,
		label: 'AWS Dynimo DB',
		color: colors.great,
		link: 'fakelink.com',
	},
	{
		key: 13,
		label: 'AWS Cognito',
		color: colors.great,
		link: 'fakelink.com',
	},
	{ key: 14, label: 'AWS SNS', color: colors.great, link: 'fakelink.com' },
	{ key: 15, label: 'AWS Lambda', color: colors.great, link: 'fakelink.com' },
	{
		key: 16,
		label: 'AWS API Gateway',
		color: colors.good,
		link: 'fakelink.com',
	},
	{
		key: 17,
		label: 'AWS API IAM',
		color: colors.average,
		link: 'fakelink.com',
	},
	{
		key: 18,
		label: 'AWS API CloudFormation',
		color: colors.average,
		link: 'fakelink.com',
	},
	{
		key: 19,
		label: 'AWS API CloudWatch',
		color: colors.average,
		link: 'fakelink.com',
	},
	{
		key: 20,
		label: 'Splunk',
		color: colors.average,
		link: 'fakelink.com',
	},
	{ key: 20, label: 'AWS RDS', color: colors.great, link: 'fakelink.com' },
];
export default skillData;
