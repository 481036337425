import * as React from 'react';
import { Typography, Avatar, Grid } from '@mui/material';
import image from '../static/images/mas.jpeg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Link from './Link';
import links from '../links';

export default function Intro({ name, subtitle, location }) {
	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
		>
			<Grid item>
				<div>
					<Typography
						variant="h3"
						component="h1"
						style={{
							color: '#3399fe',
							fontFamily: 'Raleway, sans-serif',
							fontWeight: 400,
						}}
					>
						{name}
					</Typography>
					<Typography
						variant="h4"
						style={{
							color: '#ffffff',
							fontFamily: 'Raleway, sans-serif',
							fontWeight: 400,
							marginBottom: '10px',
						}}
					>
						{subtitle}
					</Typography>
				</div>
				<div style={{ display: 'flex' }}>
					<LocationOnIcon />
					<p
						style={{
							margin: 0,
							fontSize: '20px',
							marginLeft: '5px',
						}}
					>
						{location}
					</p>
				</div>
				<div style={{ display: 'flex', marginBottom: '10px' }}>
					{links.map((linkItem) => {
						return (
							<Link
								link={linkItem}
								style={{
									color: linkItem.color,
								}}
							/>
						);
					})}
				</div>
			</Grid>

			<Grid item>
				<div style={{ padding: '10px', margin: '30px' }}>
					<Avatar
						alt={name}
						src={image}
						sx={{ width: 260, height: 266 }}
					/>
				</div>
			</Grid>
		</Grid>
	);
}
