import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import CodeIcon from '@mui/icons-material/Code';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import Typography from '@mui/material/Typography';
import timelineDescriptions from '../static/copy/timelineDescriptions';

export default function SkillTimeline() {
	const returnIcon = (icon) => {
		switch (icon) {
			case 'web':
				return <LanguageOutlinedIcon />;
			case 'graduation':
				return <SchoolOutlinedIcon />;
			case 'laptop':
				return <LaptopMacIcon />;
			case 'code':
				return <CodeIcon />;
			case 'tbc':
				return <LinearScaleIcon />;
			default:
				return <CodeIcon />;
		}
	};

	return (
		<Timeline position="alternate">
			{timelineDescriptions.map((item) => {
				return (
					<TimelineItem>
						<TimelineOppositeContent
							sx={{ m: 'auto 0' }}
							align="right"
							variant="body2"
							// color="text.secondary"
						>
							{item.secondaryText}
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector
								sx={{ bgcolor: 'secondary.main' }}
							/>
							<TimelineDot color="primary">
								{returnIcon(item.icon)}
							</TimelineDot>
							<TimelineConnector
								sx={{ bgcolor: 'secondary.main' }}
							/>
						</TimelineSeparator>
						<TimelineContent sx={{ py: '60px', px: 2 }}>
							<Typography variant="h6" component="span">
								{item.title}
							</Typography>
							<Typography>{item.text}</Typography>
						</TimelineContent>
					</TimelineItem>
				);
			})}
		</Timeline>
	);
}
