const links = [
	{
		title: 'linkedin',
		url: 'https://www.linkedin.com/in/coltonlowry/',
		color: '#8e00ff',
	},
	{
		title: 'upwork',
		url: 'https://www.upwork.com/freelancers/~01ae83cc65990496aa',
		color: '#e5ba3e',
	},
	{
		title: 'github',
		url: 'https://github.com/clowry1',
		color: '#25d059',
	},
];

export default links;
