import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import descriptions from '../static/copy/descriptions';
import SkillTimeline from './SkillTimeline';
import Skills from './Skills';
// import { Grid } from '@mui/material';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }} style={{ padding: 0 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function BasicTabs() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		// <Grid item>
		<div>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
					marginBottom: '15px',
				}}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					style={{ padding: 0 }}
				>
					<Tab
						label="Short"
						{...a11yProps(0)}
						style={{ color: value !== 0 ? '#ff6400' : '#8e00ff' }}
					/>
					<Tab
						label="Long"
						{...a11yProps(1)}
						style={{
							color: value !== 1 ? '#e5ba3e' : '#25d059',
						}}
					/>
					<Tab
						label="Journey"
						{...a11yProps(2)}
						style={{ color: value !== 2 ? '#00fcff' : '#25d059' }}
					/>
					<Tab
						label="Skillssss 🐍"
						{...a11yProps(3)}
						style={{ color: value !== 3 ? '#00fcff' : '#25d059' }}
					/>
				</Tabs>
			</Box>
			{descriptions.map((desc, index) => {
				return (
					<TabPanel
						value={value}
						index={index}
						style={{ padding: 0, minWidth: '100%' }}
					>
						<p style={{ margin: 0, padding: 0 }}>{desc.text}</p>

						{index === 2 ? <SkillTimeline /> : null}
						{index === 3 ? <Skills /> : null}
					</TabPanel>
				);
			})}
		</div>
	);
}
