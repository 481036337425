import * as React from 'react';
import Typography from '@mui/material/Typography';

export default function Link({ link }) {
	return (
		<Typography
			variant="subtitle2"
			style={{
				fontSize: '18px',
				paddingLeft: link.title === 'linkedin' ? 0 : '10px',
				paddingTop: '10px',
				// color: link.color,
			}}
		>
			<a href={link.url} style={{ color: link.color }}>
				{link.title}
			</a>
		</Typography>
	);
}
