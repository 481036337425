const shortDescription = (
	<span>
		Howdy! 👋 My name is Colton. I'm a Software Engineer for a consulting
		company called{' '}
		<a
			href="https://www.bjss.com/"
			style={{ textDecoration: 'none', color: '#3399fe' }}
		>
			BJSS
		</a>
		. I relocated from South Texas to New York in February of 2022. I'm a
		full stack engineer with a particular affinity for stacks featuring
		Javascript, Typscript, Python and/or Golang. I enjoy hanging out with
		friends and exploring New York while I'm here.
		<br />
		<br />I just got back from Manchester, England on a client engagement. I
		enjoyed the travel and making new friends from all around the world.
		That's about it, so for now, enjoy my little corner of the web.
	</span>
);

const longDescription = (
	<span>
		👋 Well hello there! My name is Colton. I'm a Software Engineer for a
		technology consulting company based out of Manhattan, New York, called{' '}
		<a
			href="https://www.bjss.com/"
			style={{ textDecoration: 'none', color: '#3399fe' }}
		>
			BJSS
		</a>
		. I'm originally from South Texas and went to college at The Univerisy
		of Texas at Austin (Hook'em). I moved to New York in February of 2022 to
		explore the east coast and advance my career.
		<br />
		<br />
		I'm a full stack engineer with a particular affinity for stacks
		featuring Javascript and Python. Although those are my favorites to work
		in, I'm comfortable in more than a few technologies, for more on that
		see{' '}
		<a
			href="https://www.rocketdollar.com/"
			style={{ textDecoration: 'none', color: '#3399fe' }}
		>
			my skills
		</a>
		. My favorite Javascript frameworks are React, React Native, and Vue.
		For Python frameworks, I enjoy working in Flask, Serverless, and Django.
		<br />
		<br />
		When I'm not coding, I enjoy hanging out with friends and exploring NY.
		I've also picked up a ping pong habit. You can catch me on the weekends
		at
		<a
			href="https://thedeadrabbit.com/"
			style={{ textDecoration: 'none', color: '#3399fe' }}
		>
			{' '}
			Daed Rabbit.{' '}
		</a>{' '}
		I'll most likely be complaining about how expensive NY is as I sip on an
		$18 gin martini. <br />
		<br />I just got back from Manchester, England on a client engagement. I
		enjoyed the travel and making new friends from all around the world.
		That's about it, so for now, enjoy my little corner of the web.
	</span>
);

const journeyDescription = (
	<span>
		In life and in my career I've had the priviledge of knowing and learning
		from some great people. Over the years I've gathered business knowledge,
		developer knowledge and just good old fashion life advice from mentors.
		I met{' '}
		<a
			href="https://www.linkedin.com/in/henryyoshida/"
			style={{ textDecoration: 'none', color: '#3399fe' }}
		>
			Henry Yoshida{' '}
		</a>{' '}
		while I was working at the genius bar at an Apple store in Austin, TX.
		That relationship opened so many doors for me that I ran through! In
		fact, I met my engineering mentor{' '}
		<a
			href="https://www.linkedin.com/in/obinna-okogbue-5546a154/"
			style={{ textDecoration: 'none', color: '#3399fe' }}
		>
			Obi
		</a>{' '}
		though one of Henry's portfolio companies in 2014. As they say, the rest
		is history. Here is a simplified overview of my career in tech and
		business.
	</span>
);

const skillsDescription = (
	<span>
		I've leared lots of different things from lots of different folks in
		lots of different fields. The skills displayed below are just a summary
		of my tech-related skillset. <br />
		<br />
		Color Scheme goes like this:
		<br />
		<br />
		<span style={{ backgroundColor: '#2EB62C', color: '#000' }}>Great</span>
		<br />
		<br />
		<span style={{ backgroundColor: '#83D475', color: '#000' }}>Good</span>
		<br />
		<br />
		<span style={{ backgroundColor: '#C5E8B7', color: '#000' }}>
			Average
		</span>
		<br />
		<br />
	</span>
);
const descriptions = [
	{
		target: 'short',
		text: shortDescription,
	},
	{
		target: 'long',
		text: longDescription,
	},
	{
		target: 'journey',
		text: journeyDescription,
	},
	{
		target: 'skils',
		text: skillsDescription,
	},
];

export default descriptions;
