import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import skillData from '../static/copy/skills';

const ListItem = styled('li')(({ theme }) => ({
	margin: theme.spacing(0.5),
}));

export default function ChipsArray() {
	return (
		<Paper
			sx={{
				display: 'flex',
				justifyContent: 'space-around',
				flexWrap: 'wrap',
				listStyle: 'none',
				p: 1,
				m: 0,
				backgroundColor: 'inherit',
			}}
			component="ul"
		>
			{skillData.map((data) => {
				let icon;

				if (data.label === 'React') {
					icon = <TagFacesIcon />;
				}

				return (
					<ListItem key={data.key}>
						<Chip
							icon={icon}
							label={data.label}
							style={{ backgroundColor: data.color }}
						/>
					</ListItem>
				);
			})}
		</Paper>
	);
}
