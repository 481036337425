const timelineDescriptions = [
	{
		title: 'Discovery 💡',
		icon: 'web',
		text: 'Learning how to make a wordpress site was interesting. Learning how to write to a csv file with a python script was dangerous. Writing HTML and C++ in highschool would peak my interest.',
		secondaryText: '2008-2013',
	},
	{
		title: '📚 Acedimic Exposure',
		icon: 'graduation',
		text: 'I entered college in 2013 and took my fisrt formal Python class at ACC. I was hooked. So hooked that I enrolled in a full-time coding bootcamp over the summer of 2015. I continued my self-taught journey as I earned my liberal arts degree from The University of Texas at Austin.',
		secondaryText: '2013-2017',
	},
	{
		title: 'First Job After College 👔',
		icon: 'laptop',
		text: 'My first role out of college was a Business Analyst. I had been working with the startup part-time for two years already. I wore a lot of hats, but got to do a decent amount of salesforce programming. I also had exposure to the engineers and picked their brains often.',
		secondaryText: '2017-2018',
	},
	{
		title: '🚀 Rocket Dollar',
		icon: 'code',
		text: "I landed my first Software Engineer title and went through a complete transformation of my skillset. I'm one of the top contributors on my team pushing production code to thousands of customers with real financial ramifications.",
		secondaryText: '2018-2022',
	},
	{
		title: 'BJSS 🥷🏻',
		icon: 'laptop',
		text: 'Moving on from Rocket Dollar was a difficult decision, but going to a big consulting firm and moving to NYC opened up a lot of doors for me. I just returned from Manchester, England, where I build and deployed software for one of the largest sports betting companies in the world.',
		secondaryText: '2022-Present',
	},
];

export default timelineDescriptions;
