import * as React from 'react';
import './App.css';
import Intro from './components/Intro';
import { Grid } from '@mui/material';
import Tabs from './components/Tabs';
import personalDetails from './static/copy/personalDetails';

export default function App() {
	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="flex-start"
			style={{
				color: '#fff',
			}}
		>
			<Grid item xs={10} lg={12}>
				<Intro
					name={personalDetails.name}
					subtitle={personalDetails.title}
					location={personalDetails.location}
				/>
			</Grid>
			<Grid item xs={11} lg={5}>
				<Tabs />
			</Grid>
		</Grid>
	);
}
